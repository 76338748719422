import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

function AutoLogout() {
  const SOCKETURL = process.env.REACT_APP_BASEURL_SOCKETURL;
  const [socketCon, setSocketCon] = useState(null);
  const [Password, setPassword] = useState("");
  const [email, setEmail] = useState(""); // State for email input
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate(); // Navigation hook to navigate back to login

  // Initialize socket connection
  useEffect(() => {
    const socket = io(SOCKETURL); // Use the socket URL from env
    setSocketCon(socket);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    // Cleanup socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [SOCKETURL]);

  // Emit user_disconnected event when user disconnects
  // Emit user_disconnected event and handle confirmation
  const handleUserDisconnected = () => {
    if (!email) {
      alert("Please enter your email");
      return;
    }
    if (!Password) {
      alert("Please enter your password");
      return;
    }

    if (socketCon) {
      const userData = { EMAIL: email, PASSWORD: Password };
      socketCon.emit("user_unlock", userData);

      // Listen for disconnect confirmation
      socketCon.on("disconnect_confirmation", (data) => {
        const { statusCode, message } = data;

        if (statusCode === 1) {
          if (message === "USER DISCONNECTED") {
            alert("You have been successfully disconnected.");
            socketCon.disconnect(); // Close the socket connection
            navigate("/"); // Navigate back to login after disconnection
          } else if (message === "INVALID EMAIL OR PASSWORD") {
            alert("Invalid email or password. Please try again.");
          }
        } else if (statusCode === 0 && message === "INTERNAL SERVER ERROR") {
          alert("An internal server error occurred. Please try again later.");
        }
      });
    }
  };

  const handleBackToLogin = () => {
    navigate("/"); // Adjust this path based on your route
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <nav className="bg-gray-500 p-4 shadow-lg">
        <div className=" mx-auto flex items-center justify-between">
          <h1 className=" text-xl font-bold">Tech23</h1>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="px-1 newpages__input"
            >
              New{" "}
            </button>
            <button
              onClick={handleBackToLogin}
              className="px-1 newpages__input"
            >
              Back to Login
            </button>
          </div>
        </div>
      </nav>

      {/* Content */}
      <div className="flex flex-col items-center justify-center min-h-[80vh]">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Disconnect Session
          </h2>

          <div className="mb-6">
            <label className="block text-lg text-gray-700 mb-2 font-bold">
              Enter Your Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-9 px-4 py-2 my-1 mx-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter your email"
              required
            />

            <label className="block text-lg text-gray-700 mb-2 font-bold">
              Enter Your Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle input type
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-9 px-4 py-2 my-1 mx-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter your password"
                required
              />
              {/* Eye Icon */}
              <span
                className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <button
            onClick={handleUserDisconnected}
            className="w-full h-9 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  );
}

export default AutoLogout;

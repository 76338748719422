import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../../CSS/EngineMaster.css";

const PilotDashboard = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;

  const [EmployeeList, setEmployeeList] = useState([]);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);

  function FetchDetails() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getallemployee?EMPCODE=${userLoggeduserCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        setEmployeeList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getEmployees List", err);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesNavbar />
      <div className="max-w-4xl mx-auto py-10 px-6">
        <h2 className="text-2xl font-bold text-gray-700 mb-6">
          Pilot Dashboard
        </h2>
        <div className="bg-white shadow-md rounded-lg p-6">
          {EmployeeList.length > 0 && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-600 font-medium">
                    Employee Code:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {EmployeeList[0]?.EMPCODE}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-600 font-medium">
                    Employee Name:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {EmployeeList[0]?.EMPNAME}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-600 font-medium">
                    Account No:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {EmployeeList[0]?.ACCOUNTNO || "N/A"}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-600 font-medium">
                    Join Date:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {new Date(EmployeeList[0]?.JOINDATE).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-600 font-medium">
                    Designation:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {EmployeeList[0]?.DESIGNATION}
                  </p>
                </div>
                <div>
                  <label className="block text-gray-600 font-medium">
                    Department:
                  </label>
                  <p className="text-gray-800 font-semibold">
                    {EmployeeList[0]?.DEPARTMENT}
                  </p>
                </div>
              </div>
            </div>
          )}
          {!EmployeeList.length && (
            <p className="text-gray-600">No employee details available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PilotDashboard;

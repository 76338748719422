import React, { useState, useEffect } from "react";
import "../CSS/Main.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import FlyImage from "../Images/FlyImage.png";
import Footer from "./Footer";
import axios from "axios";
import { userRights } from "../../Redux/actions/useRights";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
const Main = () => {
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const myState = useSelector((state) => state.getUserState);
  const [isLoading, setIsLoading] = useState(false);
  console.log(myState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!authToken) {
      navigate("/");
    }
  });

  function fetchInitialData() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: myState.user.emailId,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      )
      .then((response) => {
        console.log(response.data.recordset);
        dispatch(userRights(response.data.recordset));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred while fetching initial data:", error);
        setIsLoading(false);
      });
  }

  useEffect(fetchInitialData, []);

  const renderList = (
    <div className="image__main">
      <header>
        <Navbar />
      </header>
      <aside> {/* <Sidebar /> */}</aside>
      <header className="header_main">
        <h2></h2>
      </header>
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">Tech23 Fly Operations Software</h1>
      </header>

      {/* <h3 className="p-5 text-lg bold underline text-black flex">
        <Link className="defoult__link" to="/">
          <p> Non Flying Tasks Allocated to Pilots</p>
        </Link>{" "}
      </h3> */}
      <main>
        {" "}
        {/* <img src={FlyImage} alt="" hight="500" width="500" /> */}
      </main>
      {/* <section className="main__align_items">
    <p>
      <Link className="defoult__link" to="/Dashboard">
        Create new techlog document{" "}
      </Link>{" "}
    </p>
    <p>
      {" "}
      <Link className="defoult__link" to="/OlderDocument">
        older documents{" "}
      </Link>{" "}
    </p>
    <p>
      {" "}
      <Link className="defoult__link" to="/FuelExpence">
        fuel expense
      </Link>{" "}
    </p>
  </section> */}
      <div style={{ marginTop: "80vh" }}>
        <Footer />
      </div>
    </div>
  );

  return <>{isLoading ? <Loading /> : renderList}</>;
};

export default Main;

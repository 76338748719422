import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../CSS/Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../../src/Redux/actions/authAction";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading";
import { MdLogout } from "react-icons/md";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [selectedMaster, setSelectedMaster] = useState("");
  const myState = useSelector((state) => state.updateUserRights);
  const userState = useSelector((state) => state.getUserState);
  //  const loginState = useSelector((state) => state.getUserState);
  console.log(userState);
  console.log(userState?.user?.userName);
  const userLoggedIN = userState?.user?.userCode;
  const menuRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ModuleAccess, setModuleAccess] = useState([]);
  const [openGroup, setOpenGroup] = useState(null);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.getUserState);
  const dispatch = useDispatch();
  const navbarRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenGroup(null);
    }
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleGroupClick = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };

  async function AuthorizeUserLogin() {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: state.user?.emailId,
          MODULENAME: userState?.user?.userName,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      );
      setModuleAccess(response.data.recordsets[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred while authorizing user login:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    AuthorizeUserLogin();
  }, []);

  function logOut() {
    dispatch(logIn([]));
    navigate("/");
  }

  const handleNavigation = (page) => {
    navigate(`/${page.link}`, { state: { menu: page.menu } });
  };
  useEffect(() => {
    ///menu close anywhere click
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
        setOpenGroup(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const renderList = (
    <div>
      <nav className="centernav">
        <div className="navbar-container">
          <Link to="/Main">
            <h1 className="hover-underline-animation">TeCH23</h1>
          </Link>{" "}
          <ul
            className={`nav-menu ${isOpen ? "active" : ""}`}
            style={{ textTransform: "uppercase" }}
          >
            <div className="hover-dropdown">
              <ul className="ul__list__style__scanner">
                {ModuleAccess.length === 0 ? (
                  <div>
                    <li>NO RIGHTS</li>
                  </div>
                ) : null}

                <aside className="menu_rights menu__names">
                  {ModuleAccess.reduce((acc, item, index) => {
                    const existingType = acc.find(
                      (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
                    );

                    if (existingType) {
                      if (typeof item === "object") {
                        existingType.ids.push({
                          name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                          link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                          menu: item.OM_ACCOUNT_MODULE_MENU,
                        });
                        existingType.weblink.push(
                          item.OM_ACCOUNT_MODULE_WEB_PAGE
                        );
                      } else {
                        existingType.ids.push(item);
                        existingType.weblink.push(existingType.weblink[index]);
                      }
                    } else {
                      const newId =
                        typeof item === "object"
                          ? {
                              name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                              link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                              menu: item.OM_ACCOUNT_MODULE_MENU,
                            }
                          : item;

                      acc.push({
                        type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
                        ids: [newId],
                        weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
                      });
                    }
                    return acc;
                  }, []).map((group, index) => (
                    <div key={index} style={{ fontSize: "14px" }}>
                      <p
                        style={{
                          cursor: "pointer",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        className="menu__heading__size"
                        onClick={() => handleGroupClick(index)}
                      >
                        {group.type}
                      </p>

                      {openGroup === index && (
                        <ul
                          className="items__drop__down"
                          style={{
                            listStyleType: "none",
                            padding: "10px",
                            width: "260px",
                          }}
                        >
                          {group.ids.map((id, idIndex) => (
                            <li key={idIndex} style={{ cursor: "pointer" }}>
                              {typeof id === "object" && (
                                <span
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleNavigation(id)}
                                >
                                  {id.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </aside>
              </ul>
            </div>
          </ul>
          <div className="logged_in_user">
            <p>Welcome : {userLoggedIN}</p>

            <button
              style={{
                cursor: "pointer",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
              onClick={logOut}
              className="big__screen"
            >
              LOGOUT
            </button>
          </div>
          <div className="small__screen">
            <p className="small__screen navbar-icon" onClick={logOut}>
              <MdLogout />
            </p>
            <div
              className={`navbar-icon ${isOpen ? "rotate" : ""}`}
              onClick={toggleNavbar}
            >
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );

  return <>{isLoading ? <Loading /> : renderList}</>;
};

export default Navbar;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
function TRAININGACTUALS() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const [sheduleNumber, setSheduleNumber] = useState(0);
  const [TrainingDays, setTrainingDays] = useState(0);
  const [TrainingType, setTrainingType] = useState([]);
  const [sheludeDate, setSheludeDate] = useState("");
  const [status, setStatus] = useState("");
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );
  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [statusFilter, setStatusFilter] = useState(null);

  const [listofEmployee, setlistofEmployee] = useState([]);
  const [SelectedValueType, setSelectedValueType] = useState("");
  const [SelectedValueDays, setSelectedValueDays] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [TrainerList, setTrainerList] = useState([]);
  const [selectedTrainer, setselectedTrainer] = useState("");
  const [validity, setValidity] = useState(0);
  const [Narration, setNarration] = useState("");

  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const [Expirydate, setExpirydate] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getTrainingScheduleDeatils`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        console.log(res.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getTrainingSchedulesvsActuals`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        console.log(response.data);
        setlistofEmployee(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });

    axios
      .get(`${BASEURL}/getTrainingTypes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        setTrainingType(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getallemployee`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        setTrainerList(res.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getNewTrainingSchedule", err);
      });
  }

  function handleselected(e) {
    setSelectedValueType(e.value);
    const days = TrainingType.map((item) => {
      if (item.TRAININGTYPE === e.value) {
        return item.TRAININGDAYS;
      }
      return null; // Add this to handle map return when no match
    }).filter(Boolean); // Filter out null values
    setSelectedValueDays(days[0]);
  }

  const handleCheckboxChange = (item, isChecked) => {
    // Function to check if the item already exists in the selected employees list
    const isItemInSelected = (selected, itemToCheck) => {
      return selected.some(
        (emp) =>
          emp.EMPID === itemToCheck.EMPID &&
          emp.TRAININGTYPE === itemToCheck.TRAININGTYPE &&
          emp.SHEDULENO === itemToCheck.SHEDULENO
      );
    };

    if (isChecked) {
      if (isItemInSelected(selectedEmployees, item)) {
        // Item is already in the selected list with the same EMPID, TRAININGTYPE, and SHEDULENO
        alert(
          "This employee with the same TRAININGTYPE and SHEDULENO is already selected."
        );
      } else {
        setSelectedEmployees((prev) => [...prev, item]);
      }
    } else {
      setSelectedEmployees((prev) =>
        prev.filter(
          (employee) =>
            !(
              employee.EMPID === item.EMPID &&
              employee.TRAININGTYPE === item.TRAININGTYPE &&
              employee.SHEDULENO === item.SHEDULENO
            )
        )
      );
    }
  };

  console.log(selectedEmployees); // This should show only the checked employees

  const filteredData = listofEmployee.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["SHEDULEDATE"]);
    const eDate = new Date(item["SHEDULEDATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "EMPID",
        "EMPCODE",
        "EMPLOYEENAME",
        "TRAININGTYPE",
        "SHEDULENO",
        "SHEDULEDATE",
        "SCHEDULECREATEDBY",
        "TRAININGGIVENBY",
        "TRAININGGIVENDATE",
        "TRAININGSTATUS",
        "VALIDITY",
        "TRAININGUPDATEDDATE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    const statusMatch =
      !statusFilter ||
      item.TRAININGSTATUS === statusFilter ||
      (statusFilter === "NOT STARTED" &&
        item.TRAININGSTATUS !== "WIP" &&
        item.TRAININGSTATUS !== "COMPLETED");

    return textMatch && dateMatch && statusMatch;
  });

  const HandleSave = async () => {
    if (selectedEmployees.length === 0) {
      alert("Please select at least one employee");
      return;
    }
    if (!selectedTrainer) {
      alert("Please select Trainer");
      return;
    }
    if (!validity) {
      alert("Please provide validity");
      return;
    }
    if (!toDateFilter) {
      alert("Please provide TRAINING GIVEN DATE");
      return;
    }
    if (!Expirydate) {
      alert("Please provide Expiry date");
      return;
    }
    if (!status) {
      alert("Please provide status");
      return;
    }
    if (!Narration) {
      alert("Please provide Narration");
      return;
    }

    const confirmEdit = window.confirm("Are you sure you want to Save?");
    if (confirmEdit) {
      setisloading(true);
      const date = toDateFilter
        ? toDateFilter.slice(8, 10) +
          "/" +
          toDateFilter.slice(5, 7) +
          "/" +
          toDateFilter.slice(0, 4)
        : "";

      console.log(
        selectedTrainer,
        toDateFilter,
        validity,
        Expirydate,
        status,
        Narration
      );
      try {
        const requests = selectedEmployees.map((employee) => {
          console.log(employee.EMPID);
          const dataToSend = {
            TRAININGSCHEDULENO: employee.SHEDULENO,
            TRAININGTYPE: employee.TRAININGTYPE,
            EMPID: employee.EMPID,
            TRAINER: selectedTrainer,
            TRAININGGIVENDATE: toDateFilter,
            TRAININGVALIDITY: Number(validity),
            EXPIRYDATE: Expirydate,
            TRAININGSTATUS: status,
            TRAININGSTATUSNARRATION: Narration,
          };

          return axios.post(
            `${BASEURL}/insertTrainingScheduleactuals`,
            dataToSend,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          );
        });

        await Promise.all(requests); // Wait for all requests to complete
        alert("Training schedules saved successfully!");
        window.location.reload(); // Refresh the page after successful save
      } catch (error) {
        console.error("Error while saving training schedules:", error);
        alert("An error occurred while saving the training schedules.");
      }
    }
  };

  function handleSelectChange(e) {
    setselectedTrainer(e.value);
  }

  function handleSelectStatusChange(e) {
    setStatus(e.value);
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set orientation to landscape
    });

    // Add heading to the PDF
    doc.setFontSize(18);
    doc.text("Training Schedule Report", 14, 20); // Position the heading

    // Prepare table data
    const tableColumnHeaders = [
      "EMP ID",
      "EMP Code",
      "Employee Name",
      "Training Type",
      "Schedule No",
      "Schedule Date",
      "Created By",
      "Training Given By",
      "Training Given Date",
      "Training Status",
      "Validity",
      "Expiry Date",
      "Days Left",
      "Updated By",
      "Updated Date",
    ];

    const tableData = filteredData.map((item) => [
      item.EMPID || "NA",
      item.EMPCODE || "NA",
      item.EMPLOYEENAME || "NA",
      item.TRAININGTYPE || "NA",
      item.SHEDULENO || "NA",
      item.SHEDULEDATE
        ? `${item.SHEDULEDATE.slice(8, 10)}/${item.SHEDULEDATE.slice(
            5,
            7
          )}/${item.SHEDULEDATE.slice(0, 4)}`
        : "NA",
      item.SCHEDULECREATEDBY || "NA",
      item.TRAININGGIVENBY || "NA",
      item.TRAININGGIVENDATE
        ? `${item.TRAININGGIVENDATE.slice(
            8,
            10
          )}/${item.TRAININGGIVENDATE.slice(
            5,
            7
          )}/${item.TRAININGGIVENDATE.slice(0, 4)}`
        : "NA",
      item.TRAININGSTATUS || "NA",
      item.VALIDITY || "NA",
      item.EXPIRYDATE
        ? `${item.EXPIRYDATE.slice(8, 10)}/${item.EXPIRYDATE.slice(
            5,
            7
          )}/${item.EXPIRYDATE.slice(0, 4)}`
        : "NA",
      item.DAYSLEFTTOEXPIRE || "NA",
      item.TRAININGUPDATEDBY || "NA",
      item.TRAININGUPDATEDDATE
        ? `${item.TRAININGUPDATEDDATE.slice(
            8,
            10
          )}/${item.TRAININGUPDATEDDATE.slice(
            5,
            7
          )}/${item.TRAININGUPDATEDDATE.slice(0, 4)}`
        : "NA",
    ]);

    // Generate the table in the PDF and ensure it fits the page
    doc.autoTable({
      startY: 30, // Ensure there's space for the heading
      head: [tableColumnHeaders],
      body: tableData,
      theme: "striped",
      styles: { fontSize: 6 }, // Adjust font size to fit content
      tableWidth: "auto", // Adjust the table to fit the page
      columnStyles: {
        0: { cellWidth: 10 }, // EMP ID
        1: { cellWidth: 20 }, // EMP Code
        2: { cellWidth: 25 }, // Employee Name
        3: { cellWidth: 25 }, // Training Type
        4: { cellWidth: 10 }, // Schedule No
        5: { cellWidth: 20 }, // Schedule Date
        6: { cellWidth: 25 }, // Created By
        7: { cellWidth: 20 }, // Training Given By
        8: { cellWidth: 20 }, // Training Given Date
        9: { cellWidth: 20 }, // Training Status
        10: { cellWidth: 10 }, // Validity
        11: { cellWidth: 20 }, // Expiry Date
        12: { cellWidth: 10 }, // Days Left
        13: { cellWidth: 20 }, // Updated By
        14: { cellWidth: 20 }, // Updated Date
      },
      margin: { left: 10, right: 10 },
      pageBreak: "auto", // Auto handle page break
    });

    // Save the PDF
    doc.save("Training_Schedul_Actuals.pdf");
  };

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Training Schedule Report");

    // Set column widths
    const columnWidths = [
      15, 15, 25, 15, 15, 15, 20, 15, 20, 15, 10, 15, 10, 25, 25,
    ];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers for the report
    worksheet.addRow(["Training Schedule Report"]).font = {
      bold: true,
      size: 16,
    };
    worksheet.mergeCells("A1:O1");

    worksheet.addRow([]); // Empty row to separate the header from the table

    // Add the table headers
    const tableHeaders = [
      "EMP ID",
      "EMP Code",
      "Employee Name",
      "Training Type",
      "Schedule No",
      "Schedule Date",
      "Created By",
      "Training Given By",
      "Training Given Date",
      "Training Status",
      "Validity",
      "Expiry Date",
      "Days Left",
      "Updated By",
      "Updated Date",
    ];
    worksheet.addRow(tableHeaders).font = { bold: true };

    // Add data rows
    filteredData.forEach((item) => {
      worksheet.addRow([
        item.EMPID || "NA",
        item.EMPCODE || "NA",
        item.EMPLOYEENAME || "NA",
        item.TRAININGTYPE || "NA",
        item.SHEDULENO || "NA",
        item.SHEDULEDATE
          ? `${item.SHEDULEDATE.slice(8, 10)}/${item.SHEDULEDATE.slice(
              5,
              7
            )}/${item.SHEDULEDATE.slice(0, 4)}`
          : "NA",
        item.SCHEDULECREATEDBY || "NA",
        item.TRAININGGIVENBY || "NA",
        item.TRAININGGIVENDATE
          ? `${item.TRAININGGIVENDATE.slice(
              8,
              10
            )}/${item.TRAININGGIVENDATE.slice(
              5,
              7
            )}/${item.TRAININGGIVENDATE.slice(0, 4)}`
          : "NA",
        item.TRAININGSTATUS || "NA",
        item.VALIDITY || "NA",
        item.EXPIRYDATE
          ? `${item.EXPIRYDATE.slice(8, 10)}/${item.EXPIRYDATE.slice(
              5,
              7
            )}/${item.EXPIRYDATE.slice(0, 4)}`
          : "NA",
        item.DAYSLEFTTOEXPIRE || "NA",
        item.TRAININGUPDATEDBY || "NA",
        item.TRAININGUPDATEDDATE
          ? `${item.TRAININGUPDATEDDATE.slice(
              8,
              10
            )}/${item.TRAININGUPDATEDDATE.slice(
              5,
              7
            )}/${item.TRAININGUPDATEDDATE.slice(0, 4)}`
          : "NA",
      ]);
    });

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Training_Schedule_Actuals.xlsx";
      link.click();
    });
  };

  if (isloading) {
    return <Loading />;
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "Engine Master"}</h1>
        <div className="lg:flex items-center gap-5 my-1">
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            New
          </button>
          <button
            onClick={HandleSave}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            Save
          </button>
          <div
            onClick={handleGeneratePDF}
            className="border-l-indigo-900 rounded mx-2 "
          >
            <BsFileEarmarkPdf size={35} />
          </div>
          <div
            onClick={ExcelStockData}
            className="border-l-indigo-900 rounded mx-2 "
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
        </div>
      </header>
      <aside className="m-5">
        <section className="lg:flex  gap-10">
          <div>
            <p>TRAINER</p>
            <Select
              styles={customStyless}
              className=" text-left uppercase w-[300px]  z-[850] text-[12px]"
              required
              onChange={(e) => handleSelectChange(e)}
              defaultValue={
                selectedTrainer
                  ? { label: selectedTrainer, value: selectedTrainer }
                  : { label: "", value: "" }
              }
              options={TrainerList.map((emp) => ({
                label: emp.EMPNAME,
                value: emp.EMPNAME,
              }))}
            />
          </div>

          <div>
            <p>STATUS</p>
            <Select
              className="w-[150px] z-[800]"
              onChange={(e) => handleSelectStatusChange(e)}
              options={[
                { value: "NOT STARTED", label: "NOT STARTED" },
                { value: "WIP", label: "WIP" },
                { value: "COMPLETED", label: "COMPLETED" },
              ]}
              defaultValue={
                status
                  ? {
                      label: status,
                      value: status,
                    }
                  : { label: "", value: "" }
              }
            />
          </div>
          <div>
            <p>VALIDITY</p>
            <input
              type="number"
              className="w-[100px]"
              onChange={(e) => setValidity(e.target.value)}
            />
          </div>
          <div>
            <p>TRAINING GIVEN DATE</p>
            <input
              type="date"
              className="h-9 px-1"
              value={toDateFilter}
              onChange={(e) => setToDateFilter(e.target.value)}
            />
          </div>

          <div>
            <p>EXPIRY DATE</p>
            <input
              type="date"
              className="h-9 px-1"
              value={Expirydate}
              onChange={(e) => setExpirydate(e.target.value)}
            />
          </div>
          <div>
            <p>Narration</p>
            <textarea
              type="date"
              className="h-16 px-1 w-full"
              value={Narration}
              onChange={(e) => setNarration(e.target.value)}
            />
          </div>
        </section>
      </aside>
      <div className="z-10 lg:flex  flex-col w-full">
        <div>
          <div className="lg:flex mx-6">
            <p className="w-[70px]">Search:</p>
            <input
              className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="lg:flex items-center gap-3 lg:ml-5">
              <label className=" text-gray-700">From Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setStartdate(e.target.value);
                }}
                value={startdate.split("T")[0]}
                placeholder="From Date"
              />
            </div>
            <div className="lg:flex items-center gap-3">
              <label className=" text-gray-700">End Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
                value={enddate.split("T")[0]}
                placeholder="To Date"
              />
            </div>
            <div className="lg:flex items-center gap-3  lg:ml-5">
              <p className=" text-gray-700">Status</p>
              <Select
                options={[
                  { value: "NOT STARTED", label: "NOT STARTED" },
                  { value: "WIP", label: "WIP" },
                  { value: "COMPLETED", label: "COMPLETED" },
                ]}
                isClearable
                placeholder="Select.."
                onChange={(selectedOption) =>
                  setStatusFilter(selectedOption ? selectedOption.value : null)
                }
                className="w-[200px] z-[100]"
              />
            </div>
          </div>
          <div className="overflow-y-auto h-[500px] px-4">
            <table className="custom-table text-sm overflow__y__ relative ">
              <thead
                className="header-cell sticky top-[0px] left-[10px]"
                style={{ zIndex: 50 }}
              >
                <tr>
                  <td></td>
                  <td className="text-right">EMP ID</td>
                  <td className="text-">EMP Code</td>
                  <td className="text-">Employee Name</td>
                  <td className="text-">Training Type</td>
                  <td className="text-right">Schedule No</td>
                  <td className="text-">Schedule Date</td>
                  <td className="text-">Schedule Created By</td>
                  <td className="text-">Training Given By</td>
                  <td className="text-">Training Given Date</td>
                  <td className="text-">Training Status</td>
                  <td className="text-">Validity</td>
                  <td className="text-">Expiry Date</td>
                  <td className="text-">DAYS LEFT TO EXPIRE</td>
                  <td className="text-">TRAINING UPDATED BY</td>
                  <td className="text-">TRAINING UPDATED Date</td>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      <td>
                        {item.TRAININGSTATUS !== "COMPLETED" && (
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckboxChange(item, e.target.checked)
                            }
                            className="w-[20px]"
                          ></input>
                        )}
                      </td>
                      <td className="text-right">
                        {item.EMPID ? item.EMPID : "NA"}
                      </td>
                      <td className="text-">
                        {item.EMPCODE ? item.EMPCODE : "NA"}
                      </td>
                      <td className="text-">
                        {item.EMPLOYEENAME ? item.EMPLOYEENAME : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGTYPE ? item.TRAININGTYPE : "NA"}
                      </td>
                      <td className="text-right">
                        {item.SHEDULENO ? item.SHEDULENO : "NA"}
                      </td>
                      <td className="text-">
                        {item.SHEDULEDATE
                          ? item.SHEDULEDATE.slice(8, 10) +
                            "/" +
                            item.SHEDULEDATE.slice(5, 7) +
                            "/" +
                            item.SHEDULEDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="text-">
                        {item.SCHEDULECREATEDBY ? item.SCHEDULECREATEDBY : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGGIVENBY ? item.TRAININGGIVENBY : "N/A"}
                      </td>
                      <td className="text-">
                        {item.TRAININGGIVENDATE
                          ? item.TRAININGGIVENDATE.slice(8, 10) +
                            "/" +
                            item.TRAININGGIVENDATE.slice(5, 7) +
                            "/" +
                            item.TRAININGGIVENDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="">
                        {item.TRAININGSTATUS ? item.TRAININGSTATUS : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.VALIDITY ? item.VALIDITY : "NA"}
                      </td>
                      <td className="text-">
                        {item.EXPIRYDATE
                          ? item.EXPIRYDATE.slice(8, 10) +
                            "/" +
                            item.EXPIRYDATE.slice(5, 7) +
                            "/" +
                            item.EXPIRYDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="text-">
                        {item.DAYSLEFTTOEXPIRE ? item.DAYSLEFTTOEXPIRE : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGUPDATEDBY ? item.TRAININGUPDATEDBY : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGUPDATEDDATE
                          ? item.TRAININGUPDATEDDATE.slice(8, 10) +
                            "/" +
                            item.TRAININGUPDATEDDATE.slice(5, 7) +
                            "/" +
                            item.TRAININGUPDATEDDATE.slice(0, 4)
                          : "NA"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TRAININGACTUALS;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import "../CSS/EngineMaster.css";
import { useLocation } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ExcelJS from "exceljs";
// import { saveAs } from "file-saver";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
const STATUSUPDATESOFPILOTFLIGHTSCHEDULES = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [isloading, setIsLoading] = useState(false);

  const [PropellerList, setPropellerList] = useState([]);

  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );
  const [pilotOptions, setPilotOptions] = useState([]);
  const [PILOTNAME, setPILOTNAME] = useState("");

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [search, setSearch] = useState("");

  console.log(userLoggeduserCode);

  const [statusFilter, setStatusFilter] = useState(null);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    setIsLoading(true);
    //   getPilotSchedules
    axios
      .get(`${BASEURL}/getPilotSchedules`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setIsLoading(false);

        console.log(res.data);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error while fetching /getPilotSchedules List", err);
      });
    axios
      .get(`${BASEURL}/getpilots`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data.recordsets[0]);

        const pilots = res.data.recordsets[0].map((pilot) => ({
          value: pilot.PILOTCODE,
          label: pilot.PILOTNAME,
        }));
        setPilotOptions(pilots);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching pilots:", err);
        setIsLoading(false);
      });
  };

  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["DEPARTUREDATE"]);
    const eDate = new Date(item["DEPARTUREDATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "SCHEDULENO",
        "PILOTCODE",
        "PILOTNAME",
        "DEPARTUREDATE",
        "DEPARTURETIME",
        "DEPTFROM",
        "ARRIVALAT",
        "AIRCRAFTREGNO",
        "STATUS",
        "ACTUALDEPTDATE",
        "ACTUALDEPTTIME",
        "CREATEDBY",
        "CREATEDTIME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    const polotMatch =
      !PILOTNAME ||
      item.PILOTNAME === PILOTNAME ||
      (!item.PILOTNAME && !PILOTNAME);

    const statusMatch = !statusFilter || item.STATUS === statusFilter;

    return textMatch && dateMatch && statusMatch && polotMatch;
  });

  //   cancle cancelTripSchedued

  const cancelTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to cancel \nSCHEDULE NO: ${item.SCHEDULENO}`
    );

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE,
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "CANCEL",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: "",
            ACTUALDEPTTIME: "",
            ACTUALARRIVALDATE: "",
            ACTUALARRIVALTIME: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  //   cancle STARTlTripSchedued

  const STARTlTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to start \nSCHEDULE NO: ${item.SCHEDULENO}`
    );
    const now = new Date();
    const currentDate = new Date().toISOString();

    // Extract the current hours, minutes, and seconds
    const hours = now.getHours().toString().padStart(2, "0"); // Format to 2 digits
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Format to 2 digits
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Format to 2 digits

    // Format the current time as HH:MM:SS
    const currentDateTime = `${hours}:${minutes}:${seconds}`;

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE.slice(0, 19),
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "DEPARTURE",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: currentDate.slice(0, 10),
            ACTUALDEPTTIME: currentDateTime,
            ACTUALARRIVALDATE: "",
            ACTUALARRIVALTIME: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  const ENDlTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to End \nSCHEDULE NO: ${item.SCHEDULENO}`
    );
    const now = new Date();
    const currentDate = new Date().toISOString();

    // Extract the current hours, minutes, and seconds
    const hours = now.getHours().toString().padStart(2, "0"); // Format to 2 digits
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Format to 2 digits
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Format to 2 digits

    // Format the current time as HH:MM:SS
    const currentDateTime = `${hours}:${minutes}:${seconds}`;

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE.slice(0, 19),
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "ARRIVAL",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: item.ACTUALDEPTDATE.slice(0, 10),
            ACTUALDEPTTIME: item.ACTUALDEPTTIME.slice(11, 19),
            ACTUALARRIVALDATE: currentDate.slice(0, 10),
            ACTUALARRIVALTIME: currentDateTime,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  const totalRestDays = filteredData.reduce(
    (total, item) => total + (parseInt(item.RESTDAYS) || 0),
    0
  );

  const totalRestHours = filteredData.reduce(
    (total, item) => total + (parseInt(item.RESTHOURS) || 0),
    0
  );

  const totalFlyingDays = filteredData.reduce(
    (total, item) => total + (parseInt(item.FLYINGDAYS) || 0),
    0
  );

  const totalDaysNotAvailable = filteredData.reduce(
    (total, item) => total + (parseInt(item.DAYSNOTAVAILABLE) || 0),
    0
  );
  const generateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("MY SCHEDULE REPORT");

    const columnWidths = [
      20, 25, 20, 20, 25, 15, 15, 25, 15, 20, 20, 25, 25, 20, 15, 15,
    ];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "SCHEDULE NO",
      "DEPARTURE DATE & TIME",
      "DEPARTURE FROM",
      "ARRIVAL AT",
      "ARRIVAL DATE & TIME",
      "REST DAYS",
      "REST HOURS",
      "NEXT AVAILABLE DATE",
      "FLYING DAYS",
      "DAYS NOT AVAILABLE",
      "AIRCRAFT REG NO",
      "ACTUAL DEPARTURE DATE & TIME",
      "ACTUAL ARRIVAL DATE & TIME",
      "CREATED BY",
      "STATUS",
    ]);

    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from filteredData
    filteredData.forEach((item) => {
      const rowData = [
        item["SCHEDULENO"] || "NA",
        item["DEPARTUREDATE"]
          ? `${item["DEPARTUREDATE"].slice(8, 10)}/${item[
              "DEPARTUREDATE"
            ].slice(5, 7)}/${item["DEPARTUREDATE"].slice(0, 4)} ${item[
              "DEPARTURETIME"
            ].slice(11, 19)}`
          : "NA",
        item["DEPTFROM"] || "NA",
        item["ARRIVALAT"] || "NA",
        item["ARRIVALDATE"]
          ? `${item["ARRIVALDATE"].slice(8, 10)}/${item["ARRIVALDATE"].slice(
              5,
              7
            )}/${item["ARRIVALDATE"].slice(0, 4)} ${item["ARRIVATIME"].slice(
              11,
              19
            )}`
          : "NA",
        item["RESTDAYS"] || "0",
        item["RESTHOURS"] || "0",
        item["NEXTAVAILABLEDATE"]
          ? `${item["NEXTAVAILABLEDATE"].slice(8, 10)}/${item[
              "NEXTAVAILABLEDATE"
            ].slice(5, 7)}/${item["NEXTAVAILABLEDATE"].slice(0, 4)} ${item[
              "NEXTAVAILABLEDATE"
            ].slice(11, 19)}`
          : "NA",
        item["FLYINGDAYS"] || "NA",
        item["DAYSNOTAVAILABLE"] || "NA",
        item["AIRCRAFTREGNO"] || "NA",
        item["ACTUALDEPTDATE"]
          ? `${item["ACTUALDEPTDATE"].slice(8, 10)}/${item[
              "ACTUALDEPTDATE"
            ].slice(5, 7)}/${item["ACTUALDEPTDATE"].slice(0, 4)} ${item[
              "ACTUALDEPTTIME"
            ].slice(11, 19)}`
          : "N/A",
        item["ACTUALARRIVALDATE"]
          ? `${item["ACTUALARRIVALDATE"].slice(8, 10)}/${item[
              "ACTUALARRIVALDATE"
            ].slice(5, 7)}/${item["ACTUALARRIVALDATE"].slice(0, 4)} ${item[
              "ACTUALARRIVATIME"
            ].slice(11, 19)}`
          : "N/A",
        item["CREATEDBY"] || "NA",
        item["STATUS"] || "NA",
      ];

      worksheet.addRow(rowData);
    });

    // Calculate totals for footer row
    const totalRestDays = filteredData.reduce(
      (acc, item) => acc + (parseFloat(item["RESTDAYS"]) || 0),
      0
    );
    const totalRestHours = filteredData.reduce(
      (acc, item) => acc + (parseFloat(item["RESTHOURS"]) || 0),
      0
    );
    const totalFlyingDays = filteredData.reduce(
      (acc, item) => acc + (parseFloat(item["FLYINGDAYS"]) || 0),
      0
    );
    const totalDaysNotAvailable = filteredData.reduce(
      (acc, item) => acc + (parseFloat(item["DAYSNOTAVAILABLE"]) || 0),
      0
    );

    // Add footer row with totals
    const footerRow = worksheet.addRow([
      "Total",
      "",
      "",
      "",
      "",
      totalRestDays,
      totalRestHours,
      "",
      totalFlyingDays,
      totalDaysNotAvailable,
      "",
      "",
      "",
      "",
      "",
    ]);

    footerRow.font = { bold: true };
    footerRow.alignment = { horizontal: "right" };

    // Create a blob from the workbook and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "MY_SCHEDULE_REPORT.xlsx";
      link.click();
    });
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("MY SCHEDULE", 14, 20);

    // Define the columns and rows for the table
    const columns = [
      "SCHEDULE NO",
      "DEPARTURE DATE & TIME",
      "DEPARTURE FROM",
      "ARRIVAL AT",
      "ARRIVAL DATE & TIME",
      "REST DAYS",
      "REST HOURS",
      "NEXT AVAILABLE DATE",
      "FLYING DAYS",
      "DAYS NOT AVAILABLE",
      "AIRCRAFT REG NO",
      "ACTUAL DEPARTURE DATE & TIME",
      "ACTUAL ARRIVAL DATE & TIME",
      "CREATED BY",
      "STATUS",
    ];

    const rows = filteredData.map((item) => [
      item["SCHEDULENO"] || "NA",
      item["DEPARTUREDATE"]
        ? `${item["DEPARTUREDATE"].slice(8, 10)}/${item["DEPARTUREDATE"].slice(
            5,
            7
          )}/${item["DEPARTUREDATE"].slice(0, 4)} ${item["DEPARTURETIME"].slice(
            11,
            19
          )}`
        : "NA",
      item["DEPTFROM"] || "NA",
      item["ARRIVALAT"] || "NA",
      item["ARRIVALDATE"]
        ? `${item["ARRIVALDATE"].slice(8, 10)}/${item["ARRIVALDATE"].slice(
            5,
            7
          )}/${item["ARRIVALDATE"].slice(0, 4)} ${item["ARRIVATIME"].slice(
            11,
            19
          )}`
        : "NA",
      item["RESTDAYS"] || "0",
      item["RESTHOURS"] || "0",
      item["NEXTAVAILABLEDATE"]
        ? `${item["NEXTAVAILABLEDATE"].slice(8, 10)}/${item[
            "NEXTAVAILABLEDATE"
          ].slice(5, 7)}/${item["NEXTAVAILABLEDATE"].slice(0, 4)} ${item[
            "NEXTAVAILABLEDATE"
          ].slice(11, 19)}`
        : "NA",
      item["FLYINGDAYS"] || "",
      item["DAYSNOTAVAILABLE"] || "NA",
      item["AIRCRAFTREGNO"] || "NA",
      item["ACTUALDEPTDATE"]
        ? `${item["ACTUALDEPTDATE"].slice(8, 10)}/${item[
            "ACTUALDEPTDATE"
          ].slice(5, 7)}/${item["ACTUALDEPTDATE"].slice(0, 4)} ${item[
            "ACTUALDEPTTIME"
          ].slice(11, 19)}`
        : "NA",
      item["ACTUALARRIVALDATE"]
        ? `${item["ACTUALARRIVALDATE"].slice(8, 10)}/${item[
            "ACTUALARRIVALDATE"
          ].slice(5, 7)}/${item["ACTUALARRIVALDATE"].slice(0, 4)} ${item[
            "ACTUALARRIVATIME"
          ].slice(11, 19)}`
        : "NA",
      item["CREATEDBY"] || "NA",
      item["STATUS"] || "NA",
    ]);

    const footerRow = [
      "Total",
      "",
      "",
      "",
      "",
      totalRestDays,
      totalRestHours,
      "",
      totalFlyingDays,
      totalDaysNotAvailable,
      "",
      "",
      "",
      "",
      "",
    ];

    // Generate the table with autoTable
    doc.autoTable({
      head: [columns],
      body: rows,
      styles: { fontSize: 6 }, // Reduced font size to fit more data
      didDrawCell: function (data) {
        if (data.row.index === rows.length - 1) {
          doc.autoTable({
            body: [footerRow],
            startY: data.cursor.y + 10, // Adjust the Y position to place the footer
            tableWidth: "auto",
            styles: { fontSize: 6, halign: "right" }, // Styling for the footer row
            theme: "plain", // No borders or theme for the footer row
          });
        }
      },
    });

    // Save the PDF
    doc.save("schedule_report.pdf");
  };
  if (isloading) {
    return <Loading />;
  }
  const handleGeneratePDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Add heading to the PDF
    doc.setFontSize(18);
    doc.text("Flight Schedule Report", 14, 20); // Position the heading

    // Prepare table data
    const tableColumnHeaders = [
      "SCHEDULE NO",
      "PILOT CODE",
      "PILOT NAME",
      "DEPARTURE DATE & TIME",
      "DEPARTURE FROM",
      "ARRIVAL AT",
      "ARRIVAL DATE & TIME",
      "REST DAYS",
      "REST HOURS",
      "NEXT AVAILABLE DATE",
      "FLYING DAYS",
      "DAYS NOT AVAILABLE",
      "AIRCRAFT REG NO",
      "ACTUAL DEPARTURE DATE & TIME",
      "ACTUAL ARRIVAL DATE & TIME",
      "CREATED BY",
      "STATUS",
    ];

    const tableData = filteredData.map((item) => [
      item["SCHEDULENO"] || "NA",
      item["PILOTCODE"] || "NA",
      item["PILOTNAME"] || "NA",
      item["DEPARTUREDATE"]
        ? `${item["DEPARTUREDATE"].slice(8, 10)}/${item["DEPARTUREDATE"].slice(
            5,
            7
          )}/${item["DEPARTUREDATE"].slice(0, 4)} ${item["DEPARTURETIME"].slice(
            11,
            19
          )}`
        : "NA",
      item["DEPTFROM"] || "NA",
      item["ARRIVALAT"] || "NA",
      item["ARRIVALDATE"]
        ? `${item["ARRIVALDATE"].slice(8, 10)}/${item["ARRIVALDATE"].slice(
            5,
            7
          )}/${item["ARRIVALDATE"].slice(0, 4)} ${item["ARRIVATIME"].slice(
            11,
            19
          )}`
        : "NA",
      item["RESTDAYS"] || "0",
      item["RESTHOURS"] || "0",
      item["NEXTAVAILABLEDATE"]
        ? `${item["NEXTAVAILABLEDATE"].slice(8, 10)}/${item[
            "NEXTAVAILABLEDATE"
          ].slice(5, 7)}/${item["NEXTAVAILABLEDATE"].slice(0, 4)} ${item[
            "NEXTAVAILABLEDATE"
          ].slice(11, 19)}`
        : "NA",
      item["FLYINGDAYS"] || "NA",
      item["DAYSNOTAVAILABLE"] || "NA",
      item["AIRCRAFTREGNO"] || "NA",
      item["ACTUALDEPTDATE"]
        ? `${item["ACTUALDEPTDATE"].slice(8, 10)}/${item[
            "ACTUALDEPTDATE"
          ].slice(5, 7)}/${item["ACTUALDEPTDATE"].slice(0, 4)} ${item[
            "ACTUALDEPTTIME"
          ].slice(11, 19)}`
        : "N/A",
      item["ACTUALARRIVALDATE"]
        ? `${item["ACTUALARRIVALDATE"].slice(8, 10)}/${item[
            "ACTUALARRIVALDATE"
          ].slice(5, 7)}/${item["ACTUALARRIVALDATE"].slice(0, 4)} ${item[
            "ACTUALARRIVATIME"
          ].slice(11, 19)}`
        : "N/A",
      item["CREATEDBY"] || "NA",
      item["STATUS"] || "NA",
    ]);

    // Generate the table in the PDF
    autoTable(doc, {
      startY: 30,
      head: [tableColumnHeaders],
      body: tableData,
      theme: "striped",
      styles: { fontSize: 6 },
      margin: { left: 10, right: 10 },
    });

    // Save the PDF
    doc.save("Flight_Schedule_Report.pdf");
  };
  const generateExcelFromTableData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Flight Schedule Report");

    // Set column widths
    const columnWidths = [
      20, 15, 25, 30, 20, 20, 30, 10, 10, 30, 15, 15, 20, 30, 30, 15, 10, 10,
    ];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add table headers
    const headers = [
      "SCHEDULE NO",
      "PILOT CODE",
      "PILOT NAME",
      "DEPARTURE DATE & TIME",
      "DEPARTURE FROM",
      "ARRIVAL AT",
      "ARRIVAL DATE & TIME",
      "REST DAYS",
      "REST HOURS",
      "NEXT AVAILABLE DATE",
      "FLYING DAYS",
      "DAYS NOT AVAILABLE",
      "AIRCRAFT REG NO",
      "ACTUAL DEPARTURE DATE & TIME",
      "ACTUAL ARRIVAL DATE & TIME",
      "CREATED BY",
      "STATUS",
      "CANCEL",
      "UPDATE",
    ];
    worksheet.addRow(headers).font = { bold: true };

    // Add data rows
    filteredData.forEach((item) => {
      worksheet.addRow([
        item["SCHEDULENO"] || "NA",
        item["PILOTCODE"] || "NA",
        item["PILOTNAME"] || "NA",
        item["DEPARTUREDATE"]
          ? `${item["DEPARTUREDATE"].slice(8, 10)}/${item[
              "DEPARTUREDATE"
            ].slice(5, 7)}/${item["DEPARTUREDATE"].slice(0, 4)} ${item[
              "DEPARTURETIME"
            ].slice(11, 19)}`
          : "NA",
        item["DEPTFROM"] || "NA",
        item["ARRIVALAT"] || "NA",
        item["ARRIVALDATE"]
          ? `${item["ARRIVALDATE"].slice(8, 10)}/${item["ARRIVALDATE"].slice(
              5,
              7
            )}/${item["ARRIVALDATE"].slice(0, 4)} ${item["ARRIVATIME"].slice(
              11,
              19
            )}`
          : "NA",
        item["RESTDAYS"] || "0",
        item["RESTHOURS"] || "0",
        item["NEXTAVAILABLEDATE"]
          ? `${item["NEXTAVAILABLEDATE"].slice(8, 10)}/${item[
              "NEXTAVAILABLEDATE"
            ].slice(5, 7)}/${item["NEXTAVAILABLEDATE"].slice(0, 4)} ${item[
              "NEXTAVAILABLEDATE"
            ].slice(11, 19)}`
          : "NA",
        item["FLYINGDAYS"] || "NA",
        item["DAYSNOTAVAILABLE"] || "NA",
        item["AIRCRAFTREGNO"] || "NA",
        item["ACTUALDEPTDATE"]
          ? `${item["ACTUALDEPTDATE"].slice(8, 10)}/${item[
              "ACTUALDEPTDATE"
            ].slice(5, 7)}/${item["ACTUALDEPTDATE"].slice(0, 4)} ${item[
              "ACTUALDEPTTIME"
            ].slice(11, 19)}`
          : "N/A",
        item["ACTUALARRIVALDATE"]
          ? `${item["ACTUALARRIVALDATE"].slice(8, 10)}/${item[
              "ACTUALARRIVALDATE"
            ].slice(5, 7)}/${item["ACTUALARRIVALDATE"].slice(0, 4)} ${item[
              "ACTUALARRIVATIME"
            ].slice(11, 19)}`
          : "N/A",
        item["CREATEDBY"] || "NA",
        item["STATUS"] || "NA",
        // You can add additional columns if needed
        "", // Placeholder for CANCEL
        "", // Placeholder for UPDATE
      ]);
    });

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Flight_Schedule_Report.xlsx";
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    });
  };
  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">
          {menu ? menu : "STATUS UPDATES OF PILOT FLIGHT SCHEDULES"}
        </h1>
        <div className="flex items-center gap-5 my-1">
          {" "}
          <div
            onClick={generateExcel}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
          <div
            onClick={generatePDF}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <BsFileEarmarkPdf size={35} />
          </div>
        </div>
      </header>

      <section className="lg:flex items-center gap-8 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">Status</p>
          <Select
            options={[
              { value: "SCHEDULE", label: "SCHEDULE" },
              { value: "DEPARTURE", label: "DEPARTURE" },
              { value: "ARRIVAL", label: "ARRIVAL" },
              { value: "CANCEL", label: "CANCEL" },
            ]}
            isClearable
            placeholder="Select.."
            onChange={(selectedOption) =>
              setStatusFilter(selectedOption ? selectedOption.value : null)
            }
            className="w-[200px] z-[100]"
          />
        </div>
      </section>
      <div className=" lg:flex items-center gap-3 mx-5">
        <p className=" text-gray-700">Pilot</p>

        <Select
          options={pilotOptions}
          value={{
            value: PILOTNAME,
            label: PILOTNAME || "Select ",
          }}
          onChange={(selectedOption) => {
            if (selectedOption) {
              setPILOTNAME(selectedOption.label);
            } else {
              setPILOTNAME("");
            }
          }}
          isClearable={PILOTNAME} // This makes the select clearable
          className="lg:w-[400px] z-[300]"
        />
        <div className="lg:flex justify-end gap-2">
          <div
            className="border-l-indigo-900 rounded mx-2 "
            onClick={handleGeneratePDF}
          >
            <BsFileEarmarkPdf size={35} />
          </div>
          <div
            className="border-l-indigo-900 rounded mx-2 "
            onClick={generateExcelFromTableData}
          >
            <RiFileExcel2Line size={35} />
          </div>
        </div>
      </div>

      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table  overflow__y__ relative text-[12px] ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className="text-right">SCHEDULE NO</td>
                <td className="">PILOT CODE</td>
                <td>PILOT NAME</td>
                <td>DEPARTURE DATE & TIME</td>

                <td>DEPARTURE FROM</td>
                <td>ARRIVAL AT</td>
                <td>ARRIVAL DATE & TIME</td>
                <td className="text-right">REST DAYS</td>
                <td className="text-right">REST HOURS</td>
                <td>NEXT AVAILABLE DATE</td>
                <td className="text-right">FLYING DAYS</td>
                <td className="text-right">DAYS NOT AVAILABLE</td>
                <td>AIRCRAFT REG NO</td>
                <td>ACTUAL DEPARTURE DATE & TIME</td>
                <td>ACTUAL ARRIVAL DATE & TIME</td>
                <td>CREATED BY</td>

                <td>STATUS</td>
                <td>CANCEL</td>

                <td>UPDATE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td className=" text-right">
                      {item["SCHEDULENO"] ? item["SCHEDULENO"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["PILOTCODE"] ? item["PILOTCODE"] : "NA"}
                    </td>
                    <td className="min-w-[140px]">
                      {item["PILOTNAME"] ? item["PILOTNAME"] : "NA"}
                    </td>
                    <td className="">
                      {item["DEPARTUREDATE"]
                        ? item["DEPARTUREDATE"].slice(8, 10) +
                          "/" +
                          item["DEPARTUREDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["DEPARTURETIME"].slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["DEPTFROM"] ? item["DEPTFROM"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["ARRIVALAT"] ? item["ARRIVALAT"] : "NA"}
                    </td>
                    <td className="">
                      {item["ARRIVALDATE"]
                        ? item["ARRIVALDATE"]?.slice(8, 10) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(5, 7) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(0, 4) +
                          " " +
                          item["ARRIVATIME"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" text-right">
                      {item["RESTDAYS"] ? item["RESTDAYS"] : "0"}
                    </td>
                    <td className="text-right ">
                      {item["RESTHOURS"] ? item["RESTHOURS"] : "0"}
                    </td>
                    <td className="">
                      {item["NEXTAVAILABLEDATE"]
                        ? item["NEXTAVAILABLEDATE"]?.slice(8, 10) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(5, 7) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(0, 4) +
                          " " +
                          item["NEXTAVAILABLEDATE"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className="text-right ">
                      {item["FLYINGDAYS"] ? item["FLYINGDAYS"] : ""}
                    </td>
                    <td className="text-right ">
                      {item["DAYSNOTAVAILABLE"]
                        ? item["DAYSNOTAVAILABLE"]
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["AIRCRAFTREGNO"] ? item["AIRCRAFTREGNO"] : "NA"}
                    </td>
                    <td className="">
                      {item["ACTUALDEPTDATE"]
                        ? item["ACTUALDEPTDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALDEPTDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALDEPTTIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className="">
                      {item["ACTUALARRIVALDATE"]
                        ? item["ACTUALARRIVALDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(5, 7) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALARRIVATIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className=" ">
                      {item["CREATEDBY"] ? item["CREATEDBY"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["STATUS"] ? item["STATUS"] : "NA"}
                    </td>{" "}
                    <td className=" ">
                      {!item.ACTUALDEPTDATE && item["STATUS"] !== "CANCEL" && (
                        <button
                          className="px-1 rounded py-1  border-none outline-none "
                          onClick={() => {
                            cancelTripSchedued(item);
                          }}
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                    <td className=" ">
                      {item["STATUS"] !== "CANCEL" &&
                        item["STATUS"] === "SCHEDULE" && (
                          <div>
                            {" "}
                            <button
                              className="px-1 rounded py-1 border-none outline-none"
                              onClick={() => {
                                STARTlTripSchedued(item);
                              }}
                            >
                              START
                            </button>
                          </div>
                        )}
                      {item["STATUS"] !== "CANCEL" &&
                        item["STATUS"] === "DEPARTURE" && (
                          <div>
                            {" "}
                            <button
                              className="px-1 rounded py-1 border-none outline-none"
                              onClick={() => {
                                ENDlTripSchedued(item);
                              }}
                            >
                              END
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot className="text-right font-bold">
              <tr>
                <td colSpan={7} className="">
                  Total
                </td>
                <td>{totalRestDays}</td>
                <td>{totalRestHours}</td>
                <td></td> {/* Empty cell for NEXT AVAILABLE DATE */}
                <td>{totalFlyingDays}</td>
                <td>{totalDaysNotAvailable}</td>
                <td colSpan={6}></td> {/* Empty cells for other columns */}
              </tr>
            </tfoot>
          </table>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default STATUSUPDATESOFPILOTFLIGHTSCHEDULES;

import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
function HISTORICTRAININGACTUALS() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [isloading, setisloading] = useState(false);
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const [sheduleNumber, setSheduleNumber] = useState(0);
  const [TrainingDays, setTrainingDays] = useState(0);
  const [TrainingType, setTrainingType] = useState([]);
  const [sheludeNumber, setsheludeNumber] = useState("");
  const [status, setStatus] = useState("");
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );
  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [statusFilter, setStatusFilter] = useState(null);
  const [startdate2, setStartdate2] = useState(localStartOfYear.toISOString());
  const [enddate2, setEnddate2] = useState(currentDate.toISOString());
  const [statusFilter2, setStatusFilter2] = useState(null);
  const [listofEmployee, setlistofEmployee] = useState([]);
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [SelectedTrainingType, setSelectedTrainingType] = useState("");
  const [SelectedValueDays, setSelectedValueDays] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [TrainerList, setTrainerList] = useState([]);
  const [selectedTrainer, setselectedTrainer] = useState("");
  const [validity, setValidity] = useState(0);
  const [Narration, setNarration] = useState("");

  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const [Expirydate, setExpirydate] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const [movedTable, setMovedTable] = useState([]);
  const movedTableRef = useRef(null);
  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    setisloading(true);

    axios
      .get(`${BASEURL}/getTrainingSchedulesvsActuals`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        console.log(response.data);
        setlistofEmployee(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });

    axios
      .get(`${BASEURL}/getTrainingTypes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        setTrainingType(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getallemployee`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        setTrainerList(res.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getNewTrainingSchedule", err);
      });
  }

  const filteredData = listofEmployee.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["SHEDULEDATE"]);
    const eDate = new Date(item["SHEDULEDATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "EMPID",
        "EMPCODE",
        "EMPLOYEENAME",
        "TRAININGTYPE",
        "SHEDULENO",
        "SHEDULEDATE",
        "SCHEDULECREATEDBY",
        "TRAININGGIVENBY",
        "TRAININGGIVENDATE",
        "TRAININGSTATUS",
        "VALIDITY",
        "TRAININGUPDATEDDATE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    const statusMatch =
      !statusFilter ||
      item.TRAININGSTATUS === statusFilter ||
      (statusFilter === "NOT STARTED" &&
        item.TRAININGSTATUS !== "WIP" &&
        item.TRAININGSTATUS !== "COMPLETED");

    const employeeMatch =
      !selectedEmployee ||
      item.EMPLOYEENAME === selectedEmployee ||
      (!item.EMPLOYEENAME && !selectedEmployee);
    const TrainerMatch =
      !selectedTrainer ||
      item.TRAININGGIVENBY === selectedTrainer ||
      (!item.TRAININGGIVENBY && !selectedTrainer);
    const TrainingTypeMatch =
      !SelectedTrainingType ||
      item.TRAININGTYPE === SelectedTrainingType ||
      (!item.TRAININGTYPE && !SelectedTrainingType);

    return (
      textMatch &&
      dateMatch &&
      statusMatch &&
      employeeMatch &&
      TrainerMatch &&
      TrainingTypeMatch
    );
  });

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add first table
    doc.setFontSize(12);
    doc.text("TRAININGS CARRIED OUT", 10, 10);

    const headers1 = [
      "EMP ID",
      "EMP Code",
      "Employee Name",
      "Training Type",
      "Schedule No",
      "Schedule Date",
      "Schedule Created By",
      "Training Given By",
      "Training Given Date",
      "Training Status",
      "Validity",
      "Expiry Date",
      "TRAINING UPDATED BY",
      "TRAINING UPDATED Date",
    ];

    const rows1 = filteredData.map((item) => [
      item.EMPID || "NA",
      item.EMPCODE || "NA",
      item.EMPLOYEENAME || "NA",
      item.TRAININGTYPE || "NA",
      item.SHEDULENO || "NA",
      item.SHEDULEDATE
        ? item.SHEDULEDATE.slice(8, 10) +
          "/" +
          item.SHEDULEDATE.slice(5, 7) +
          "/" +
          item.SHEDULEDATE.slice(0, 4)
        : "NA",
      item.SCHEDULECREATEDBY || "NA",
      item.TRAININGGIVENBY || "N/A",
      item.TRAININGGIVENDATE
        ? item.TRAININGGIVENDATE.slice(8, 10) +
          "/" +
          item.TRAININGGIVENDATE.slice(5, 7) +
          "/" +
          item.TRAININGGIVENDATE.slice(0, 4)
        : "NA",
      item.TRAININGSTATUS || "NOT STARTED",
      item.VALIDITY || "NA",
      item.EXPIRYDATE
        ? item.EXPIRYDATE.slice(8, 10) +
          "/" +
          item.EXPIRYDATE.slice(5, 7) +
          "/" +
          item.EXPIRYDATE.slice(0, 4)
        : "NA",
      item.TRAININGUPDATEDBY || "NA",
      item.TRAININGUPDATEDDATE
        ? item.TRAININGUPDATEDDATE.slice(8, 10) +
          "/" +
          item.TRAININGUPDATEDDATE.slice(5, 7) +
          "/" +
          item.TRAININGUPDATEDDATE.slice(0, 4)
        : "NA",
    ]);

    // Generate PDF for first table
    doc.autoTable({
      head: [headers1],
      body: rows1,
      startY: 20,
      styles: { fontSize: 6 },
    });

    // Add second table
    doc.addPage();
    doc.text("Training Details", 10, 10);

    const headers2 = [
      "EMP ID",
      "Employee Name",
      "Schedule Date & Time",
      "Training Status",
      "Training Type",
      "Narration",
    ];

    const rows2 = filteredDatadetails.map((row) => [
      row.EMPID,
      row.EMPPLOYEEMNAME || "NA",
      row.STATUSDATETIME
        ? `${row.STATUSDATETIME.slice(8, 10)}/${row.STATUSDATETIME.slice(
            5,
            7
          )}/${row.STATUSDATETIME.slice(0, 4)} ${row.STATUSDATETIME.slice(
            11,
            16
          )}`
        : "NA",
      row.TRAININGSTATUS || "NA",
      row.TRAININGTYPE || "NA",
      row.STATUSNARRATION || "NA",
    ]);

    // Generate PDF for second table
    doc.autoTable({
      head: [headers2],
      styles: { fontSize: 6 },
      body: rows2,
      startY: doc.autoTable.previous.finalY + 10,
    });

    // Save PDF
    doc.save("Training_Report.pdf");
  };

  const generateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Training Report");

    // Set column widths
    const columnWidths = [
      15, 15, 25, 15, 15, 20, 25, 20, 20, 15, 15, 15, 20, 20,
    ];
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add first table headers
    const headers1 = [
      "EMP ID",
      "EMP Code",
      "Employee Name",
      "Training Type",
      "Schedule No",
      "Schedule Date",
      "Schedule Created By",
      "Training Given By",
      "Training Given Date",
      "Training Status",
      "Validity",
      "Expiry Date",
      "TRAINING UPDATED BY",
      "TRAINING UPDATED Date",
    ];
    worksheet.addRow(headers1).font = { bold: true };

    // Add first table data
    filteredData.forEach((item) => {
      worksheet.addRow([
        item.EMPID || "NA",
        item.EMPCODE || "NA",
        item.EMPLOYEENAME || "NA",
        item.TRAININGTYPE || "NA",
        item.SHEDULENO || "NA",
        item.SHEDULEDATE
          ? item.SHEDULEDATE.slice(8, 10) +
            "/" +
            item.SHEDULEDATE.slice(5, 7) +
            "/" +
            item.SHEDULEDATE.slice(0, 4)
          : "NA",
        item.SCHEDULECREATEDBY || "NA",
        item.TRAININGGIVENBY || "N/A",
        item.TRAININGGIVENDATE
          ? item.TRAININGGIVENDATE.slice(8, 10) +
            "/" +
            item.TRAININGGIVENDATE.slice(5, 7) +
            "/" +
            item.TRAININGGIVENDATE.slice(0, 4)
          : "NA",
        item.TRAININGSTATUS || "NOT STARTED",
        item.VALIDITY || "NA",
        item.EXPIRYDATE
          ? item.EXPIRYDATE.slice(8, 10) +
            "/" +
            item.EXPIRYDATE.slice(5, 7) +
            "/" +
            item.EXPIRYDATE.slice(0, 4)
          : "NA",
        item.TRAININGUPDATEDBY || "NA",
        item.TRAININGUPDATEDDATE
          ? item.TRAININGUPDATEDDATE.slice(8, 10) +
            "/" +
            item.TRAININGUPDATEDDATE.slice(5, 7) +
            "/" +
            item.TRAININGUPDATEDDATE.slice(0, 4)
          : "NA",
      ]);
    });

    // Add second table headers
    worksheet.addRow([]); // Empty row
    const headers2 = [
      "EMP ID",
      "Employee Name",
      "Schedule Date & Time",
      "Training Status",
      "Training Type",
      "Narration",
    ];
    worksheet.addRow(headers2).font = { bold: true };

    // Add second table data
    filteredDatadetails.forEach((row) => {
      worksheet.addRow([
        row.EMPID,
        row.EMPPLOYEEMNAME || "NA",
        row.STATUSDATETIME
          ? `${row.STATUSDATETIME.slice(8, 10)}/${row.STATUSDATETIME.slice(
              5,
              7
            )}/${row.STATUSDATETIME.slice(0, 4)} ${row.STATUSDATETIME.slice(
              11,
              16
            )}`
          : "NA",
        row.TRAININGSTATUS || "NA",
        row.TRAININGTYPE || "NA",
        row.STATUSNARRATION || "NA",
      ]);
    });

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Training_Report.xlsx";
      document.body.appendChild(link); // Append to body
      link.click(); // Simulate click
      document.body.removeChild(link); // Remove from body
    });
  };
  if (isloading) {
    return <Loading />;
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  console.log(selectedEmployee);
  console.log(selectedTrainer);
  console.log(SelectedTrainingType);

  const handleDoubleClick = (row) => {
    console.log(row);
    setsheludeNumber(row);
    axios
      .get(`${BASEURL}/getTrainingScheduleDeatils?SCHEDULENO=${row}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        setMovedTable(res.data);
        console.log(res.data);

        setTimeout(() => {
          if (movedTableRef.current) {
            movedTableRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 80);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getNewTrainingSchedule", err);
      });
  };

  const filteredDatadetails = movedTable.filter((item) => {
    const searchData = search2.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["STATUSDATETIME"]);
    const eDate = new Date(item["STATUSDATETIME"]);

    const textMatch = searchData.every((term) => {
      return [
        "EMPID",
        "EMPCODE",
        "EMPLOYEENAME",
        "TRAININGTYPE",
        "SHEDULENO",
        "STATUSDATETIME",
        "SCHEDULECREATEDBY",
        "TRAININGGIVENBY",
        "TRAININGGIVENDATE",
        "TRAININGSTATUS",
        "STATUSNARRATION",
        "STATUSDATETIME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const dateMatch =
      (!startdate2 || sDate >= new Date(startdate2)) &&
      (!enddate2 || eDate <= new Date(enddate2));

    const statusMatch =
      !statusFilter2 ||
      item.TRAININGSTATUS === statusFilter2 ||
      (statusFilter2 === "NOT STARTED" &&
        item.TRAININGSTATUS !== "WIP" &&
        item.TRAININGSTATUS !== "COMPLETED");

    const employeeMatch =
      !selectedEmployee ||
      item.EMPLOYEENAME === selectedEmployee ||
      (!item.EMPLOYEENAME && !selectedEmployee);
    const TrainerMatch =
      !selectedTrainer ||
      item.TRAININGGIVENBY === selectedTrainer ||
      (!item.TRAININGGIVENBY && !selectedTrainer);
    const TrainingTypeMatch =
      !SelectedTrainingType ||
      item.TRAININGTYPE === SelectedTrainingType ||
      (!item.TRAININGTYPE && !SelectedTrainingType);

    return (
      textMatch && dateMatch && statusMatch
      //  &&
      // employeeMatch &&
      // TrainerMatch &&
      // TrainingTypeMatch
    );
  });

  console.log(filteredDatadetails);
  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "TRAININGS CARRIED OUT"}</h1>
      </header>

      <aside className="m-5">
        <section className="lg:flex  gap-10">
          <div>
            <p>EMPLOYEE</p>
            <Select
              styles={customStyless}
              className=" text-left uppercase w-[300px]  z-[850] text-[12px]"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setselectedEmployee(selectedOption.value);
                } else {
                  setselectedEmployee("");
                }
              }}
              isClearable={selectedEmployee} // This makes the select clearable
              defaultValue={
                selectedEmployee
                  ? { label: selectedEmployee, value: selectedEmployee }
                  : { label: "", value: "" }
              }
              options={TrainerList.map((emp) => ({
                label: emp.EMPNAME,
                value: emp.EMPNAME,
              }))}
            />
          </div>
          <div>
            <p>TRAINER</p>
            <Select
              styles={customStyless}
              className=" text-left uppercase w-[300px]  z-[850] text-[12px]"
              required
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setselectedTrainer(selectedOption.value);
                } else {
                  setselectedTrainer("");
                }
              }}
              isClearable={selectedTrainer} // This makes the select clearable
              defaultValue={
                selectedTrainer
                  ? { label: selectedTrainer, value: selectedTrainer }
                  : { label: "", value: "" }
              }
              options={TrainerList.map((emp) => ({
                label: emp.EMPNAME,
                value: emp.EMPNAME,
              }))}
            />
          </div>
          <div>
            <p>TRAINING TYPE</p>
            <Select
              styles={customStyless}
              className="text-left uppercase z-[100]"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setSelectedTrainingType(selectedOption.value);
                } else {
                  setSelectedTrainingType("");
                }
              }}
              defaultValue={
                SelectedTrainingType
                  ? { label: SelectedTrainingType, value: SelectedTrainingType }
                  : { label: "", value: "" }
              }
              isClearable={SelectedTrainingType} // This makes the select clearable
              options={[{ value: "", label: "" }].concat(
                TrainingType.map((item) => ({
                  label: item.TRAININGTYPE,
                  value: item.TRAININGTYPE,
                }))
              )}
            />
          </div>
          <div
            onClick={generatePDF}
            className="border-l-indigo-900 rounded mx-2 "
          >
            <BsFileEarmarkPdf size={35} />
          </div>
          <div
            onClick={generateExcel}
            className="border-l-indigo-900 rounded mx-2"
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
        </section>
      </aside>
      <div className="z-10 lg:flex  flex-col w-full">
        <div>
          <div className="lg:flex mx-6">
            <p className="w-[70px]">Search:</p>
            <input
              className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="lg:flex items-center gap-3 lg:mx-5">
              <label className=" text-gray-700">From Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setStartdate(e.target.value);
                }}
                value={startdate.split("T")[0]}
                placeholder="From Date"
              />
            </div>
            <div className="lg:flex items-center gap-3">
              <label className=" text-gray-700">End Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
                value={enddate.split("T")[0]}
                placeholder="To Date"
              />
            </div>
            <div className="lg:flex items-center gap-3  lg:ml-5">
              <p className=" text-gray-700">Status</p>
              <Select
                options={[
                  { value: "NOT STARTED", label: "NOT STARTED" },
                  { value: "WIP", label: "WIP" },
                  { value: "COMPLETED", label: "COMPLETED" },
                ]}
                isClearable
                placeholder="Select.."
                onChange={(selectedOption) =>
                  setStatusFilter(selectedOption ? selectedOption.value : null)
                }
                className="w-[200px] z-[100]"
              />
            </div>
          </div>
          <div className="overflow-y-auto h-[500px] px-4">
            <table className="custom-table text-sm overflow__y__ relative ">
              <thead
                className="header-cell sticky top-[0px] left-[10px]"
                style={{ zIndex: 50 }}
              >
                <tr>
                  <td className="text-right">EMP ID</td>
                  <td className="text-">EMP Code</td>
                  <td className="text-">Employee Name</td>
                  <td className="text-">Training Type</td>
                  <td className="text-right">Schedule No</td>
                  <td className="text-">Schedule Date</td>
                  <td className="text-">Schedule Created By</td>
                  <td className="text-">Training Given By</td>
                  <td className="text-">Training Given Date</td>
                  <td className="text-">Training Status</td>
                  <td className="text-">Validity</td>
                  <td className="text-">Expiry Date</td>
                  <td className="text-">TRAINING UPDATED BY</td>
                  <td className="text-">TRAINING UPDATED Date</td>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                      onDoubleClick={() => handleDoubleClick(item?.SHEDULENO)}
                    >
                      <td className="text-right">
                        {item.EMPID ? item.EMPID : "NA"}
                      </td>
                      <td className="text-">
                        {item.EMPCODE ? item.EMPCODE : "NA"}
                      </td>
                      <td className="text-">
                        {item.EMPLOYEENAME ? item.EMPLOYEENAME : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGTYPE ? item.TRAININGTYPE : "NA"}
                      </td>
                      <td className="text-right">
                        {item.SHEDULENO ? item.SHEDULENO : "NA"}
                      </td>
                      <td className="text-">
                        {item.SHEDULEDATE
                          ? item.SHEDULEDATE.slice(8, 10) +
                            "/" +
                            item.SHEDULEDATE.slice(5, 7) +
                            "/" +
                            item.SHEDULEDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="text-">
                        {item.SCHEDULECREATEDBY ? item.SCHEDULECREATEDBY : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGGIVENBY ? item.TRAININGGIVENBY : "N/A"}
                      </td>
                      <td className="text-">
                        {item.TRAININGGIVENDATE
                          ? item.TRAININGGIVENDATE.slice(8, 10) +
                            "/" +
                            item.TRAININGGIVENDATE.slice(5, 7) +
                            "/" +
                            item.TRAININGGIVENDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="">
                        {item.TRAININGSTATUS
                          ? item.TRAININGSTATUS
                          : "NOT STARTED"}
                      </td>
                      <td className="text-right">
                        {item.VALIDITY ? item.VALIDITY : "NA"}
                      </td>
                      <td className="text-">
                        {item.EXPIRYDATE
                          ? item.EXPIRYDATE.slice(8, 10) +
                            "/" +
                            item.EXPIRYDATE.slice(5, 7) +
                            "/" +
                            item.EXPIRYDATE.slice(0, 4)
                          : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGUPDATEDBY ? item.TRAININGUPDATEDBY : "NA"}
                      </td>
                      <td className="text-">
                        {item.TRAININGUPDATEDDATE
                          ? item.TRAININGUPDATEDDATE.slice(8, 10) +
                            "/" +
                            item.TRAININGUPDATEDDATE.slice(5, 7) +
                            "/" +
                            item.TRAININGUPDATEDDATE.slice(0, 4)
                          : "NA"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div ref={movedTableRef}>
          {" "}
          {/* Ref to scroll to */}
          <div className="lg:flex justify-center mb-4 mt-2">
            <h2 className="font-bold text-xl ml-4">Details of Training</h2>
          </div>
          <b className="mx-6 my-2 mb-4">
            SCHEDULE NO : <span>{sheludeNumber}</span>
          </b>
          <div className="lg:flex mx-6">
            <p className="w-[70px]">Search:</p>
            <input
              className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              value={search2}
              onChange={(e) => setSearch2(e.target.value)}
            />
            <div className="lg:flex items-center gap-3 lg:mx-5">
              <label className=" text-gray-700">From Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setStartdate2(e.target.value);
                }}
                value={startdate2.split("T")[0]}
                placeholder="From Date"
              />
            </div>
            <div className="lg:flex items-center gap-3">
              <label className=" text-gray-700">End Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setEnddate2(e.target.value);
                }}
                value={enddate2.split("T")[0]}
                placeholder="To Date"
              />
            </div>
            <div className="lg:flex items-center gap-3  lg:ml-5">
              <p className=" text-gray-700">Status</p>
              <Select
                options={[
                  { value: "", label: "" },
                  { value: "NOT STARTED", label: "NOT STARTED" },
                  { value: "WIP", label: "WIP" },
                  { value: "COMPLETED", label: "COMPLETED" },
                ]}
                isClearable
                placeholder="Select.."
                onChange={(selectedOption) =>
                  setStatusFilter2(selectedOption ? selectedOption.value : null)
                }
                className="w-[200px] z-[100]"
              />
            </div>
          </div>
          <div className="overflow-y-auto h-[500px] px-4">
            <table className="custom-table text-sm overflow__y__ relative ">
              <thead
                className="header-cell sticky top-[0px] left-[10px]"
                style={{ zIndex: 50 }}
              >
                <tr>
                  <td className="text-right">EMP ID</td>
                  <td className="text-">Employee Name</td>

                  <td className="text-">Schedule Date & Time</td>
                  <td className="text-">Training Status</td>
                  <td className="text-">Training Type</td>
                  <td className="text-"> Narration</td>
                </tr>
              </thead>
              <tbody>
                {filteredDatadetails.map((row, index) => (
                  <tr key={index}>
                    <td className="text-right">{row.EMPID}</td>
                    <td>{row.EMPPLOYEEMNAME || "NA"}</td>
                    <td>
                      {" "}
                      {row.STATUSDATETIME
                        ? `${row.STATUSDATETIME.slice(
                            8,
                            10
                          )}/${row.STATUSDATETIME.slice(
                            5,
                            7
                          )}/${row.STATUSDATETIME.slice(0, 4)} ${
                            row.STATUSDATETIME.slice(11, 16) // Extract time (HH:MM)
                          }`
                        : "NA"}
                    </td>
                    <td>{row.TRAININGSTATUS || "NA"}</td>
                    <td>{row.TRAININGTYPE || "NA"}</td>
                    <td>{row.STATUSNARRATION}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HISTORICTRAININGACTUALS;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for API requests
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons for password visibility

const ForgotPassword = () => {
  const SOCKETURL = process.env.REACT_APP_BASEURL_SOCKETURL;

  const [step, setStep] = useState(1); // Step 1: Input email/phone, Step 2: Input OTP, Step 3: New Password
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // Toggle password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/"); // Adjust this path based on your route
  };

  // Function to send OTP
  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${SOCKETURL}/forgotpassword/generateOtp`,
        {
          EMAIL: emailOrPhone,
        }
      );
      console.log(response.data.message);
      setStep(2); // Proceed to OTP input
    } catch (error) {
      console.error("Error sending OTP", error.response.data.message);
    }
  };

  // Function to verify OTP
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${SOCKETURL}/forgotpassword/verifyOtp`,
        {
          EMAIL: emailOrPhone,
          OTP: otp,
        }
      );
      console.log("OTP verified", response.data.message);
      setStep(3); // Proceed to reset password
    } catch (error) {
      alert("Invalid OTP");
      console.error("OTP verification failed", error.response.data.message);
    }
  };

  // Function to reset password
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${SOCKETURL}/forgotpassword/changePassword`,
        {
          EMAIL: emailOrPhone,
          NEWPASSWORD: newPassword,
        }
      );
      console.log("Password reset successful", response.data.message);
      alert("Password has been reset successfully");
      navigate("/"); // Redirect to login page after password reset
    } catch (error) {
      console.error("Password reset failed", error.response.data.message);
    }
  };

  return (
    <div className="bg-gray-100 min-h-[100vh]">
      <nav className="bg-gray-500 p-4 shadow-lg">
        <div className=" mx-auto flex items-center justify-between">
          <h1 className=" text-xl font-bold">Tech23</h1>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="px-1 newpages__input"
            >
              New{" "}
            </button>
            <button
              onClick={handleBackToLogin}
              className="px-1 newpages__input"
            >
              Back to Login
            </button>
          </div>
        </div>
      </nav>

      <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
          {/* Step 1: Email/Phone input */}
          {step === 1 && (
            <form onSubmit={handleSendOtp}>
              <h2 className="text-2xl font-semibold mb-6 text-center">
                Forgot Password
              </h2>
              <div className="mb-4">
                <label className="block text-lg text-gray-700 mb-2 font-bold">
                  Email
                </label>
                <input
                  type="email"
                  value={emailOrPhone}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                  className="w-full h-9 px-4 py-2 my-1 mx-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full h-9 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Send OTP
              </button>
            </form>
          )}

          {/* Step 2: OTP input */}
          {step === 2 && (
            <form onSubmit={handleVerifyOtp}>
              <h2 className="text-2xl font-semibold mb-6 text-center">
                Enter OTP
              </h2>
              <p className="text-sm text-gray-700 mb-4 text-center">
                OTP sent to: <strong>{emailOrPhone}</strong>
              </p>
              <div className="mb-4">
                <label className="block text-lg text-gray-700 mb-2 font-bold">
                  OTP
                </label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter OTP"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setStep(1)} // Go back to Step 1
                  className="text-indigo-600 hover:underline"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-full h-9 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Verify OTP
                </button>
              </div>
            </form>
          )}

          {/* Step 3: Reset Password */}
          {step === 3 && (
            <form onSubmit={handleResetPassword}>
              <h2 className="text-2xl font-semibold mb-6 text-center">
                Reset Password
              </h2>
              <p className="text-sm text-gray-700 mb-4 text-center">
                Mail: <strong>{emailOrPhone}</strong>
              </p>
              <div className="mb-4">
                <label className="block text-lg text-gray-700 mb-2 font-bold">
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter new password"
                    required
                  />
                  <span
                    className="absolute right-3 top-3 cursor-pointer"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-lg text-gray-700 mb-2 font-bold">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Confirm new password"
                    required
                  />
                  <span
                    className="absolute right-3 top-3 cursor-pointer"
                    onClick={() =>
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }
                  >
                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="w-full h-9 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
